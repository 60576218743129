/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Profile } from "./Profile";
import {
  ProfileFromJSON,
  ProfileFromJSONTyped,
  ProfileToJSON,
} from "./Profile";

/**
 *
 * @export
 * @interface Status
 */
export interface Status {
  /**
   *
   * @type {boolean}
   * @memberof Status
   */
  authenticated: boolean;
  /**
   *
   * @type {Profile}
   * @memberof Status
   */
  profile?: Profile;
}

/**
 * Check if a given object implements the Status interface.
 */
export function instanceOfStatus(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "authenticated" in value;

  return isInstance;
}

export function StatusFromJSON(json: any): Status {
  return StatusFromJSONTyped(json, false);
}

export function StatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Status {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    authenticated: json["authenticated"],
    profile: !exists(json, "profile")
      ? undefined
      : ProfileFromJSON(json["profile"]),
  };
}

export function StatusToJSON(value?: Status | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    authenticated: value.authenticated,
    profile: ProfileToJSON(value.profile),
  };
}
