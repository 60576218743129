import * as i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { LanguageCode, getLanguage } from "@client/utils/language";
import { initYup } from "@client/utils/validation";

const language = getLanguage();

async function loadTranslations(lang: LanguageCode) {
  switch (lang) {
    default:
    case "de":
      return import("@client/locales/de/translation.json");
    // case "it":
    //   return import("@client/locales/it/translation.json");
    // case "fr":
    //   return import("@client/locales/fr/translation.json");
  }
}

export async function initi18n() {
  // Load translation file. This will block, intentionally. It's safest to set
  // up all translations before including more code, so we can't
  // unintentionally translate something at load time with the wrong locale.
  let translations = {};
  if (language !== "en") {
    translations = (await loadTranslations(language)).default;
  }

  // ... and init.
  i18n.use(initReactI18next).init({
    lng: language,

    returnNull: false,

    // we init with resources
    resources: {
      [language]: { translations },
    },

    // fallbackLng: "en",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
    saveMissing: true,
  });

  initYup();
}

// declare custom type options so the return is always a string.
declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}
