/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Page
 */
export interface Page {
  /**
   *
   * @type {string}
   * @memberof Page
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Page
   */
  slug: string;
  /**
   *
   * @type {string}
   * @memberof Page
   */
  content: string;
}

/**
 * Check if a given object implements the Page interface.
 */
export function instanceOfPage(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "slug" in value;
  isInstance = isInstance && "content" in value;

  return isInstance;
}

export function PageFromJSON(json: any): Page {
  return PageFromJSONTyped(json, false);
}

export function PageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Page {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    slug: json["slug"],
    content: json["content"],
  };
}

export function PageToJSON(value?: Page | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    slug: value.slug,
    content: value.content,
  };
}
