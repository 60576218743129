/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  CheckToken,
  CreateLogin,
  Page,
  PagedPuzzle,
  Profile,
  Puzzle,
  PuzzleParticipant,
  PuzzleParticipateSchema,
  SignupSchema,
  Status,
  UpdateProfile,
  Upload,
} from "../models/index";
import {
  CheckTokenFromJSON,
  CheckTokenToJSON,
  CreateLoginFromJSON,
  CreateLoginToJSON,
  PageFromJSON,
  PageToJSON,
  PagedPuzzleFromJSON,
  PagedPuzzleToJSON,
  ProfileFromJSON,
  ProfileToJSON,
  PuzzleFromJSON,
  PuzzleToJSON,
  PuzzleParticipantFromJSON,
  PuzzleParticipantToJSON,
  PuzzleParticipateSchemaFromJSON,
  PuzzleParticipateSchemaToJSON,
  SignupSchemaFromJSON,
  SignupSchemaToJSON,
  StatusFromJSON,
  StatusToJSON,
  UpdateProfileFromJSON,
  UpdateProfileToJSON,
  UploadFromJSON,
  UploadToJSON,
} from "../models/index";

export interface AccountsCheckTokenRequest {
  checkToken: CheckToken;
}

export interface AccountsLoginRequest {
  createLogin: CreateLogin;
}

export interface AccountsSignupRequest {
  signupSchema: SignupSchema;
}

export interface AccountsUpdateProfileRequest {
  updateProfile: UpdateProfile;
}

export interface PagesGetPageRequest {
  slug: string;
}

export interface PuzzlesGetPuzzleRequest {
  id: string;
}

export interface PuzzlesGetPuzzleListRequest {
  orderBy?: PuzzlesGetPuzzleListOrderByEnum;
  liveOnly?: boolean;
  archivedOnly?: boolean;
  winnerOnly?: boolean;
  limit?: number;
  offset?: number;
}

export interface PuzzlesParticipateRequest {
  id: string;
  puzzleParticipateSchema: PuzzleParticipateSchema;
}

export interface UploadsUploadRequest {
  file: Blob;
}

/**
 *
 */
export class DefaultApi extends runtime.BaseAPI {
  /**
   * Check Token
   */
  async accountsCheckTokenRaw(
    requestParameters: AccountsCheckTokenRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.checkToken === null ||
      requestParameters.checkToken === undefined
    ) {
      throw new runtime.RequiredError(
        "checkToken",
        "Required parameter requestParameters.checkToken was null or undefined when calling accountsCheckToken."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/check-token/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: CheckTokenToJSON(requestParameters.checkToken),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Check Token
   */
  async accountsCheckToken(
    requestParameters: AccountsCheckTokenRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.accountsCheckTokenRaw(requestParameters, initOverrides);
  }

  /**
   * Delete Account
   */
  async accountsDeleteAccountRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/account/delete/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete Account
   */
  async accountsDeleteAccount(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.accountsDeleteAccountRaw(initOverrides);
  }

  /**
   * Get Profile
   */
  async accountsGetProfileRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Profile>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/profile/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProfileFromJSON(jsonValue)
    );
  }

  /**
   * Get Profile
   */
  async accountsGetProfile(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Profile> {
    const response = await this.accountsGetProfileRaw(initOverrides);
    return await response.value();
  }

  /**
   * Get Status
   */
  async accountsGetStatusRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Status>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/status/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      StatusFromJSON(jsonValue)
    );
  }

  /**
   * Get Status
   */
  async accountsGetStatus(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Status> {
    const response = await this.accountsGetStatusRaw(initOverrides);
    return await response.value();
  }

  /**
   * Login
   */
  async accountsLoginRaw(
    requestParameters: AccountsLoginRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.createLogin === null ||
      requestParameters.createLogin === undefined
    ) {
      throw new runtime.RequiredError(
        "createLogin",
        "Required parameter requestParameters.createLogin was null or undefined when calling accountsLogin."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/login/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: CreateLoginToJSON(requestParameters.createLogin),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Login
   */
  async accountsLogin(
    requestParameters: AccountsLoginRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.accountsLoginRaw(requestParameters, initOverrides);
  }

  /**
   * Logout
   */
  async accountsLogoutRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/logout/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Logout
   */
  async accountsLogout(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.accountsLogoutRaw(initOverrides);
  }

  /**
   * Send Account Data
   */
  async accountsSendAccountDataRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/account/data/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Send Account Data
   */
  async accountsSendAccountData(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.accountsSendAccountDataRaw(initOverrides);
  }

  /**
   * Signup
   */
  async accountsSignupRaw(
    requestParameters: AccountsSignupRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.signupSchema === null ||
      requestParameters.signupSchema === undefined
    ) {
      throw new runtime.RequiredError(
        "signupSchema",
        "Required parameter requestParameters.signupSchema was null or undefined when calling accountsSignup."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/signup/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SignupSchemaToJSON(requestParameters.signupSchema),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Signup
   */
  async accountsSignup(
    requestParameters: AccountsSignupRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.accountsSignupRaw(requestParameters, initOverrides);
  }

  /**
   * Update Profile
   */
  async accountsUpdateProfileRaw(
    requestParameters: AccountsUpdateProfileRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Profile>> {
    if (
      requestParameters.updateProfile === null ||
      requestParameters.updateProfile === undefined
    ) {
      throw new runtime.RequiredError(
        "updateProfile",
        "Required parameter requestParameters.updateProfile was null or undefined when calling accountsUpdateProfile."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/profile/`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: UpdateProfileToJSON(requestParameters.updateProfile),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProfileFromJSON(jsonValue)
    );
  }

  /**
   * Update Profile
   */
  async accountsUpdateProfile(
    requestParameters: AccountsUpdateProfileRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Profile> {
    const response = await this.accountsUpdateProfileRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get Page
   */
  async pagesGetPageRaw(
    requestParameters: PagesGetPageRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Page>> {
    if (
      requestParameters.slug === null ||
      requestParameters.slug === undefined
    ) {
      throw new runtime.RequiredError(
        "slug",
        "Required parameter requestParameters.slug was null or undefined when calling pagesGetPage."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/pages/{slug}/`.replace(
          `{${"slug"}}`,
          encodeURIComponent(String(requestParameters.slug))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PageFromJSON(jsonValue)
    );
  }

  /**
   * Get Page
   */
  async pagesGetPage(
    requestParameters: PagesGetPageRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Page> {
    const response = await this.pagesGetPageRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get Puzzle
   */
  async puzzlesGetPuzzleRaw(
    requestParameters: PuzzlesGetPuzzleRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Puzzle>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling puzzlesGetPuzzle."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/puzzles/{id}/`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PuzzleFromJSON(jsonValue)
    );
  }

  /**
   * Get Puzzle
   */
  async puzzlesGetPuzzle(
    requestParameters: PuzzlesGetPuzzleRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Puzzle> {
    const response = await this.puzzlesGetPuzzleRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get Puzzle List
   */
  async puzzlesGetPuzzleListRaw(
    requestParameters: PuzzlesGetPuzzleListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PagedPuzzle>> {
    const queryParameters: any = {};

    if (requestParameters.orderBy !== undefined) {
      queryParameters["order_by"] = requestParameters.orderBy;
    }

    if (requestParameters.liveOnly !== undefined) {
      queryParameters["live_only"] = requestParameters.liveOnly;
    }

    if (requestParameters.archivedOnly !== undefined) {
      queryParameters["archived_only"] = requestParameters.archivedOnly;
    }

    if (requestParameters.winnerOnly !== undefined) {
      queryParameters["winner_only"] = requestParameters.winnerOnly;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/puzzles/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PagedPuzzleFromJSON(jsonValue)
    );
  }

  /**
   * Get Puzzle List
   */
  async puzzlesGetPuzzleList(
    requestParameters: PuzzlesGetPuzzleListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PagedPuzzle> {
    const response = await this.puzzlesGetPuzzleListRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Participate
   */
  async puzzlesParticipateRaw(
    requestParameters: PuzzlesParticipateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PuzzleParticipant>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling puzzlesParticipate."
      );
    }

    if (
      requestParameters.puzzleParticipateSchema === null ||
      requestParameters.puzzleParticipateSchema === undefined
    ) {
      throw new runtime.RequiredError(
        "puzzleParticipateSchema",
        "Required parameter requestParameters.puzzleParticipateSchema was null or undefined when calling puzzlesParticipate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/puzzles/{id}/participate/`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PuzzleParticipateSchemaToJSON(
          requestParameters.puzzleParticipateSchema
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PuzzleParticipantFromJSON(jsonValue)
    );
  }

  /**
   * Participate
   */
  async puzzlesParticipate(
    requestParameters: PuzzlesParticipateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PuzzleParticipant> {
    const response = await this.puzzlesParticipateRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Uploads a file to the file server and return its path. The file will be deleted in a short time unless it is captured before that.
   * Upload
   */
  async uploadsUploadRaw(
    requestParameters: UploadsUploadRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Upload>> {
    if (
      requestParameters.file === null ||
      requestParameters.file === undefined
    ) {
      throw new runtime.RequiredError(
        "file",
        "Required parameter requestParameters.file was null or undefined when calling uploadsUpload."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [
      { contentType: "multipart/form-data" },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file !== undefined) {
      formParams.append("file", requestParameters.file as any);
    }

    const response = await this.request(
      {
        path: `/api/upload/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UploadFromJSON(jsonValue)
    );
  }

  /**
   * Uploads a file to the file server and return its path. The file will be deleted in a short time unless it is captured before that.
   * Upload
   */
  async uploadsUpload(
    requestParameters: UploadsUploadRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Upload> {
    const response = await this.uploadsUploadRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}

/**
 * @export
 */
export const PuzzlesGetPuzzleListOrderByEnum = {
  Latest: "latest",
  Oldest: "oldest",
} as const;
export type PuzzlesGetPuzzleListOrderByEnum =
  typeof PuzzlesGetPuzzleListOrderByEnum[keyof typeof PuzzlesGetPuzzleListOrderByEnum];
