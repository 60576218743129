import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  CheckToken,
  CreateLogin,
  Profile,
  Status,
} from "@generated/app_server_api/models";

import { makeApi } from "./utils";

export type { Status };
export const PROFILE = "profile";

export function useStatus() {
  const api = makeApi();
  return useQuery([PROFILE, "status"], () => api.accountsGetStatus());
}

export function useProfile() {
  const api = makeApi();
  return useQuery([PROFILE], () => api.accountsGetProfile());
}

export function useUpdateProfile() {
  const api = makeApi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (profile: Profile) =>
      api.accountsUpdateProfile({ updateProfile: profile }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [PROFILE] }),
  });
}

export function useCreateLogin() {
  const api = makeApi();
  return useMutation({
    mutationFn: (login: CreateLogin) =>
      api.accountsLogin({ createLogin: login }),
  });
}

export function useCheckLoginToken() {
  const api = makeApi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (checkToken: CheckToken) =>
      api.accountsCheckToken({ checkToken }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [PROFILE] }),
  });
}

export function useLogout() {
  const api = makeApi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => api.accountsLogout(),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [PROFILE] }),
  });
}

export function useSendAccountData() {
  const api = makeApi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => api.accountsSendAccountData(),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [PROFILE] }),
  });
}

export function useDeleteAccount() {
  const api = makeApi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => api.accountsDeleteAccount(),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [PROFILE] }),
  });
}
