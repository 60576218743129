import * as React from "react";

import { Status, useStatus } from "@client/api/account";

const StatusContext = React.createContext<Status | undefined>(undefined);

export function AuthStatusProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const statusApi = useStatus();

  // Render the app as soon as we know if we're authenticated. Some views,
  // like e.g., the meeting creator rely on knowing this as soon as they
  // mount.
  return (
    <StatusContext.Provider value={statusApi.data}>
      {children}
    </StatusContext.Provider>
  );
}

export function useAuthStatus() {
  return React.useContext(StatusContext);
}
