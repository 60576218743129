/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CheckToken
 */
export interface CheckToken {
  /**
   *
   * @type {string}
   * @memberof CheckToken
   */
  magic_token: string;
}

/**
 * Check if a given object implements the CheckToken interface.
 */
export function instanceOfCheckToken(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "magic_token" in value;

  return isInstance;
}

export function CheckTokenFromJSON(json: any): CheckToken {
  return CheckTokenFromJSONTyped(json, false);
}

export function CheckTokenFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CheckToken {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    magic_token: json["magic_token"],
  };
}

export function CheckTokenToJSON(value?: CheckToken | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    magic_token: value.magic_token,
  };
}
