/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Picture } from "./Picture";
import {
  PictureFromJSON,
  PictureFromJSONTyped,
  PictureToJSON,
} from "./Picture";

/**
 *
 * @export
 * @interface Customer
 */
export interface Customer {
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  name: string;
  /**
   *
   * @type {Picture}
   * @memberof Customer
   */
  picture?: Picture;
}

/**
 * Check if a given object implements the Customer interface.
 */
export function instanceOfCustomer(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "name" in value;

  return isInstance;
}

export function CustomerFromJSON(json: any): Customer {
  return CustomerFromJSONTyped(json, false);
}

export function CustomerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Customer {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
    picture: !exists(json, "picture")
      ? undefined
      : PictureFromJSON(json["picture"]),
  };
}

export function CustomerToJSON(value?: Customer | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    picture: PictureToJSON(value.picture),
  };
}
