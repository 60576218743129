/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface PuzzleParticipant
 */
export interface PuzzleParticipant {
  /**
   *
   * @type {boolean}
   * @memberof PuzzleParticipant
   */
  deleted: boolean;
  /**
   *
   * @type {number}
   * @memberof PuzzleParticipant
   */
  solution?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof PuzzleParticipant
   */
  correct: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PuzzleParticipant
   */
  lottery: boolean;
}

/**
 * Check if a given object implements the PuzzleParticipant interface.
 */
export function instanceOfPuzzleParticipant(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "deleted" in value;
  isInstance = isInstance && "correct" in value;
  isInstance = isInstance && "lottery" in value;

  return isInstance;
}

export function PuzzleParticipantFromJSON(json: any): PuzzleParticipant {
  return PuzzleParticipantFromJSONTyped(json, false);
}

export function PuzzleParticipantFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PuzzleParticipant {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    deleted: json["deleted"],
    solution: !exists(json, "solution") ? undefined : json["solution"],
    correct: json["correct"],
    lottery: json["lottery"],
  };
}

export function PuzzleParticipantToJSON(value?: PuzzleParticipant | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    deleted: value.deleted,
    solution: value.solution,
    correct: value.correct,
    lottery: value.lottery,
  };
}
