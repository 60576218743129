/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Puzzle } from "./Puzzle";
import { PuzzleFromJSON, PuzzleFromJSONTyped, PuzzleToJSON } from "./Puzzle";

/**
 *
 * @export
 * @interface PagedPuzzle
 */
export interface PagedPuzzle {
  /**
   *
   * @type {Array<Puzzle>}
   * @memberof PagedPuzzle
   */
  items: Array<Puzzle>;
  /**
   *
   * @type {number}
   * @memberof PagedPuzzle
   */
  count: number;
}

/**
 * Check if a given object implements the PagedPuzzle interface.
 */
export function instanceOfPagedPuzzle(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "items" in value;
  isInstance = isInstance && "count" in value;

  return isInstance;
}

export function PagedPuzzleFromJSON(json: any): PagedPuzzle {
  return PagedPuzzleFromJSONTyped(json, false);
}

export function PagedPuzzleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PagedPuzzle {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: (json["items"] as Array<any>).map(PuzzleFromJSON),
    count: json["count"],
  };
}

export function PagedPuzzleToJSON(value?: PagedPuzzle | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items: (value.items as Array<any>).map(PuzzleToJSON),
    count: value.count,
  };
}
