import { QueryClient } from "@tanstack/react-query";
import cookies from "cookies-js";
import { find } from "lodash";

import {
  ApiError,
  ForbiddenError,
  NetworkError,
  NotAuthenticatedError,
  RatelimitedError,
  ServiceError,
  ValidationError,
} from "@client/api/http";
import { getLanguage } from "@client/utils/language";
import {
  Configuration,
  ConfigurationParameters,
  DefaultApi,
  ErrorContext,
  Middleware,
} from "@generated/app_server_api";

// Constant to use in react-query's queryKey building.
export const QueryType = {
  LIST: "list",
  INFINITE: "infinite",
  DETAIL: "detail",
};

/**
 * Middleware which maps a) network failures and b) server errors to our own
 * exception classes.
 */
const errorMappingMiddleware: Middleware = {
  // This is triggered when fetch throws, which is basically when the network
  // isn't available. Let's convert it to something more comfy.
  onError: async (context: ErrorContext) => {
    throw new NetworkError(
      context.init.method || "get",
      context.url,
      (context.error as Error).toString()
    );
  },
  // Here, the server was able to respond. We'll now translate error codes to
  // real exceptions. We don't have to tackle the success case, as it is done
  // by the runtime.
  post: async (context) => {
    if (context.response.ok) {
      return;
    }

    // So, the json will contain the error envelope and body. Let's convert it
    // to an actual error and reject the promise.
    const cause = await context.response.json();
    const status = context.response.status;

    if (status == 401) {
      return Promise.reject(new NotAuthenticatedError(cause, status));
    } else if (status == 403) {
      return Promise.reject(new ForbiddenError(cause, status));
    } else if (status == 429) {
      return Promise.reject(new RatelimitedError(cause, status));
    } else if (status == 400) {
      // NB: ServiceError and ValidationError are both a 400, so check the
      // cause.
      switch (cause.type) {
        case "ServiceError":
          return Promise.reject(new ServiceError(cause, status));
        case "ValidationError":
          return Promise.reject(new ValidationError(cause, status));
      }
    }
    return Promise.reject(new ApiError(cause, status));
  },
};

/**
 * Creates the API client for accessing the app server.
 */
export function makeApi(opts: ConfigurationParameters = {}) {
  return new DefaultApi(
    new Configuration({
      // This adds a scheme and host for testing environments. We're running
      // tests on nodejs, which requires fully qualified URLs. Luckily, it's
      // not problem when running in the browser, as browsers don't really
      // care, hence have an empty string there (default is "localhost", of
      // all things).
      basePath: globalThis.TEST_HOST || "",
      // The usual, make django serve us the correct language and not deny us
      // PUT and POST requests.
      headers: {
        "X-CSRFtoken": cookies.get("csrftoken"),
        "Accept-Language": getLanguage(),
      },
      // Use django's session and send all cookies.
      credentials: "include",
      //
      middleware: [errorMappingMiddleware],
      ...opts,
    })
  );
}

type ListResource = { id: number }[];

/**
 * Given a resource type (a string) and an id, this will invalidate all list
 * resources this id is actually part of
 */
export function invalidateListQueries(
  client: QueryClient,
  resource: string,
  id: string
): void {
  client
    .getQueriesData<ListResource>([resource, QueryType.LIST])
    .forEach(([key, data]) => {
      if (find(data, { id })) {
        client.invalidateQueries(key);
      }
    });
}
