/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Picture
 */
export interface Picture {
  /**
   *
   * @type {string}
   * @memberof Picture
   */
  path: string;
  /**
   *
   * @type {string}
   * @memberof Picture
   */
  thumb: string;
}

/**
 * Check if a given object implements the Picture interface.
 */
export function instanceOfPicture(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "path" in value;
  isInstance = isInstance && "thumb" in value;

  return isInstance;
}

export function PictureFromJSON(json: any): Picture {
  return PictureFromJSONTyped(json, false);
}

export function PictureFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Picture {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    path: json["path"],
    thumb: json["thumb"],
  };
}

export function PictureToJSON(value?: Picture | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    path: value.path,
    thumb: value.thumb,
  };
}
