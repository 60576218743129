/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Upload
 */
export interface Upload {
  /**
   *
   * @type {string}
   * @memberof Upload
   */
  path: string;
  /**
   *
   * @type {string}
   * @memberof Upload
   */
  thumb?: string;
  /**
   *
   * @type {string}
   * @memberof Upload
   */
  mime: string;
  /**
   *
   * @type {boolean}
   * @memberof Upload
   */
  is_image: boolean;
}

/**
 * Check if a given object implements the Upload interface.
 */
export function instanceOfUpload(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "path" in value;
  isInstance = isInstance && "mime" in value;
  isInstance = isInstance && "is_image" in value;

  return isInstance;
}

export function UploadFromJSON(json: any): Upload {
  return UploadFromJSONTyped(json, false);
}

export function UploadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Upload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    path: json["path"],
    thumb: !exists(json, "thumb") ? undefined : json["thumb"],
    mime: json["mime"],
    is_image: json["is_image"],
  };
}

export function UploadToJSON(value?: Upload | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    path: value.path,
    thumb: value.thumb,
    mime: value.mime,
    is_image: value.is_image,
  };
}
