import cookies from "cookies-js";

import {
  ErrorEnvelope,
  RatelimitedErrorEnvelope,
  ServiceErrorEnvelope,
  ValidationErrorEnvelope,
} from "@client/api/types";
import { getLanguage } from "@client/utils/language";

type ContentType = "application/json" | undefined;

/**
 * An error instance which we can use to throw back to clients. It's just
 * a small wrapper around our error envelope.
 */
export class ApiError extends Error {
  status: number | undefined;

  error: ErrorEnvelope;

  constructor(error: ErrorEnvelope, status: number | undefined) {
    super(error.type);
    this.error = error;
    this.status = status;
  }
}

/**
 * The server could not be reached at all, typically, because the network
 * was down.
 */
export class NetworkError extends ApiError {
  constructor(method: string, url: string, message?: string) {
    super(
      {
        type: "NetworkError",
        detail: {
          msg: `${message || "Error"} (method="${method}", url="${url}")`,
        },
      },
      undefined
    );
  }
}

/**
 * 401 Unauthorized - which is really Unauthenticated, because it indicates
 * the user is not logged in. This temporary and may go away after logging in
 * or providing credentials.
 */
export class NotAuthenticatedError extends ApiError {}

/**
 * 403 Forbidden. The user is authenticated but doesn't have access to the
 * given resource. This is permanent and likely won't go away at all.
 */
export class ForbiddenError extends ApiError {}

/**
 * These take the following form (as defined by django ninja and pydantic):
 *
 * {
 *   "type": "ValidationError",
 *   "detail": [
 *       {
 *           "loc": [
 *               "body",
 *               "data",
 *               "__root__" | "<fieldname>"
 *           ],
 *           "msg": "The user already exist" | "This field is required",
 *           "type": "value_error"
 *       }
 *   ],
 *   "code": null
 * }
 *
 * This is a wrapper around the envelope, also parsing it (especially the
 * "loc" part). Since we only ever have a single payload ("body.data"), we can
 * simplify it to just a mapping.
 */

export class ValidationError extends ApiError {
  /**
   * The root key is used for non-field or root-level (pydantic term) errors.
   * It's just the term from pydantic, so let's not question it too much.
   */
  static ROOT_KEY = "__root__";

  detail: { loc: string[]; msg: string; type: string }[];

  fieldErrors: { [key: string]: string };

  rootError?: string;

  constructor(error: ValidationErrorEnvelope, status: number | undefined) {
    super(error, status);
    this.detail = error.detail;

    this.fieldErrors = {};

    this.detail.forEach(({ loc, msg }) => {
      // `loc` is the path to the actual field, which might be nice for nested
      // data use cases. We don't have that currently, let's just extract the
      // field name.
      const actualLoc = loc[loc.length - 1];

      // Determine if it's a root or field error.
      if (actualLoc == ValidationError.ROOT_KEY) {
        this.rootError = msg;
      } else {
        this.fieldErrors = { ...this.fieldErrors, [actualLoc]: msg };
      }
    }, {});
  }
}

export class ServiceError extends ApiError {
  detail: string;

  code: number;

  constructor(error: ServiceErrorEnvelope, status: number | undefined) {
    super(error, status);
    this.detail = error.detail;
    this.code = error.code;
  }
}

export class RatelimitedError extends ApiError {
  detail: string;

  constructor(error: RatelimitedErrorEnvelope, status: number | undefined) {
    super(error, status);
    this.detail = error.detail;
  }
}

/**
 * Convert result to json by default, so we don't have to in client code.
 */
function handleResponse(response: Response) {
  if (
    response.headers.get("content-length") === "0" ||
    response.status === 204
  ) {
    return Promise.resolve(undefined);
  }

  // All dandy, start parsing json and return
  if (response.ok) {
    return response.json();
  }

  // So, the json will contain the error envelope and body. Let's convert it
  // to an actual error and reject the promise.
  return response.json().then((cause: ErrorEnvelope) => {
    const status = response.status;
    if (status == 401) {
      return Promise.reject(new NotAuthenticatedError(cause, status));
    } else if (status == 403) {
      return Promise.reject(new ForbiddenError(cause, status));
    } else if (status == 400) {
      // NB: ServiceError and ValidationError are both a 400, so check the
      // cause.
      switch (cause.type) {
        case "ServiceError":
          return Promise.reject(new ServiceError(cause, status));
        case "ValidationError":
          return Promise.reject(new ValidationError(cause, status));
      }
    }
    return Promise.reject(new ApiError(cause, status));
  });
}

/**
 * Generic fetch request builder. Adds language support, the CSRF token,
 * and adds cookies to the transport.
 */
function buildData(
  method: "GET" | "PUT" | "POST" | "DELETE" | "PATCH",
  body: BodyInit | null = null,
  contentType: ContentType = undefined
): RequestInit {
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set("X-CSRFtoken", cookies.get("csrftoken"));
  requestHeaders.set("Accept-Language", getLanguage());
  requestHeaders.set("Accept", "application/json");
  if (contentType) {
    requestHeaders.set("Content-Type", contentType);
  }

  return {
    // Make the session cookie go with the request.
    credentials: "include",
    headers: requestHeaders,
    method,
    body,
  };
}

export async function get<T = any>(url: string): Promise<T> {
  const req = fetch(buildUrl(url), buildData("GET", null, "application/json"));
  return req.then(handleResponse);
}

export async function post<T = any>(url: string, data: any): Promise<T> {
  const req = fetch(
    buildUrl(url),
    buildData("POST", JSON.stringify(data), "application/json")
  );
  return req.then(handleResponse);
}

/**
 * Use this for posting form data (e.g., for files). The body object is expected
 * to be an instance of FormData and will be form encoded (not JSON).
 */
export function postFormData<T = any>(url: string, body: FormData): Promise<T> {
  // Used for uploading files like this:
  // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#uploading_a_file

  const req = fetch(
    buildUrl(url),
    buildData(
      "POST",
      body,
      // Don't set content type to application/json, it will be set to
      // `multipart/form-data; boundary=...` (boundary being important).
      undefined
    )
  );
  return req.then(handleResponse);
}

export async function put<T = any>(url: string, data: any): Promise<T> {
  const req = fetch(
    buildUrl(url),
    buildData("PUT", JSON.stringify(data), "application/json")
  );
  return req.then(handleResponse);
}

export async function patch<T = any>(url: string, data: any): Promise<T> {
  const req = fetch(
    buildUrl(url),
    buildData("PATCH", JSON.stringify(data), "application/json")
  );
  return req.then(handleResponse);
}

export async function destroy(url: string) {
  const req = fetch(
    buildUrl(url),
    buildData("DELETE", null, "application/json")
  );
  return req.then(handleResponse);
}

/**
 * This adds a scheme and host for testing environments. We're running tests
 * on nodejs, which requires fully qualified URLs (luckily, the browser does
 * not).
 */
export function buildUrl(url: string): string {
  return globalThis.TEST_HOST ? `${TEST_HOST}${url}` : url;
}
