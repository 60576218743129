import { t } from "i18next";
import * as Yup from "yup";

export function initYup() {
  Yup.setLocale({
    mixed: {
      required: t("This field is required"),
    },
    string: {
      email: t("Must be a valid email address"),
      min: t("Must be at least ${min} characters"),
      max: t("Must be at most ${max} characters"),
    },
  });
}
