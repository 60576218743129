/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { UpdateProfilePicture } from "./UpdateProfilePicture";
import {
  UpdateProfilePictureFromJSON,
  UpdateProfilePictureFromJSONTyped,
  UpdateProfilePictureToJSON,
} from "./UpdateProfilePicture";

/**
 *
 * @export
 * @interface UpdateProfile
 */
export interface UpdateProfile {
  /**
   *
   * @type {string}
   * @memberof UpdateProfile
   */
  salutation?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateProfile
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateProfile
   */
  last_name?: string;
  /**
   *
   * @type {UpdateProfilePicture}
   * @memberof UpdateProfile
   */
  picture?: UpdateProfilePicture;
  /**
   *
   * @type {string}
   * @memberof UpdateProfile
   */
  birthday?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateProfile
   */
  street?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateProfile
   */
  zipcode?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateProfile
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateProfile
   */
  phone_number?: string;
}

/**
 * Check if a given object implements the UpdateProfile interface.
 */
export function instanceOfUpdateProfile(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function UpdateProfileFromJSON(json: any): UpdateProfile {
  return UpdateProfileFromJSONTyped(json, false);
}

export function UpdateProfileFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateProfile {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    salutation: !exists(json, "salutation") ? undefined : json["salutation"],
    first_name: !exists(json, "first_name") ? undefined : json["first_name"],
    last_name: !exists(json, "last_name") ? undefined : json["last_name"],
    picture: !exists(json, "picture")
      ? undefined
      : UpdateProfilePictureFromJSON(json["picture"]),
    birthday: !exists(json, "birthday") ? undefined : json["birthday"],
    street: !exists(json, "street") ? undefined : json["street"],
    zipcode: !exists(json, "zipcode") ? undefined : json["zipcode"],
    city: !exists(json, "city") ? undefined : json["city"],
    phone_number: !exists(json, "phone_number")
      ? undefined
      : json["phone_number"],
  };
}

export function UpdateProfileToJSON(value?: UpdateProfile | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    salutation: value.salutation,
    first_name: value.first_name,
    last_name: value.last_name,
    picture: UpdateProfilePictureToJSON(value.picture),
    birthday: value.birthday,
    street: value.street,
    zipcode: value.zipcode,
    city: value.city,
    phone_number: value.phone_number,
  };
}
