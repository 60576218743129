import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import * as ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";

import { ApiError } from "./api/http";
import { AuthStatusProvider } from "./authStatus";
import { initi18n as init18n } from "./i18n";

// See https://vitejs.dev/guide/backend-integration.html
// See https://vitejs.dev/config/build-options.html#build-polyfillmodulepreload
// See https://github.com/MrBin99/django-vite/pull/57/
// Needed for firefox and safari module preloads (which we don't use
// currently). I leave it here for future reference.
// import/no-unresolved
// import "vite/modulepreload-polyfill";

// Main entry
(async function main() {
  await init18n();

  // Load app widget asynchonously. We do it async, so as we don't
  // accidentally define a yup schema before calling init18n(). Obv, there
  // are other solutions to this problem, see:
  // https://github.com/jquense/yup/issues/293
  import("@client/app/App").then(({ App }) => {
    render(App);
  });
})();

async function render(App: React.ElementType) {
  // We'll work with one global query client. Hope this is a good idea.
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // Most 4XX responses shouldn't be retried. It's likely the problem
        // won't go away if we do so, thus don't retry in these cases. See
        // https://stackoverflow.com/questions/51770071/
        retry: (failureCount: number, error: any) => {
          if (
            error instanceof ApiError &&
            error.status &&
            error.status >= 400 &&
            error.status < 500 &&
            ![408, 425, 429].includes(error.status)
          ) {
            return false;
          }
          // otherwise, retry up to 3 times (which is the default)
          return failureCount <= 3;
        },
      },
    },
  });

  const rootElement = document.getElementById("app")!;
  if (!rootElement.innerHTML) {
    const root = ReactDOM.createRoot(rootElement);
    root.render(
      <QueryClientProvider client={queryClient}>
        <AuthStatusProvider>
          <App />
        </AuthStatusProvider>
        <Toaster
          position="bottom-right"
          toastOptions={{
            // Having a minimum size helps with toast.promise, as replacing the
            // loading message with either the success or fail message doesn't
            // jump around the toast.
            className: "text-sm min-w-[13rem]",
          }}
        />
      </QueryClientProvider>
    );
  }
}
