/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ProfilePicture
 */
export interface ProfilePicture {
  /**
   *
   * @type {string}
   * @memberof ProfilePicture
   */
  path: string;
  /**
   *
   * @type {string}
   * @memberof ProfilePicture
   */
  thumb: string;
}

/**
 * Check if a given object implements the ProfilePicture interface.
 */
export function instanceOfProfilePicture(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "path" in value;
  isInstance = isInstance && "thumb" in value;

  return isInstance;
}

export function ProfilePictureFromJSON(json: any): ProfilePicture {
  return ProfilePictureFromJSONTyped(json, false);
}

export function ProfilePictureFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProfilePicture {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    path: json["path"],
    thumb: json["thumb"],
  };
}

export function ProfilePictureToJSON(value?: ProfilePicture | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    path: value.path,
    thumb: value.thumb,
  };
}
