/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CreateLogin
 */
export interface CreateLogin {
  /**
   *
   * @type {string}
   * @memberof CreateLogin
   */
  email: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateLogin
   */
  remember_me?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateLogin
   */
  next?: string;
}

/**
 * Check if a given object implements the CreateLogin interface.
 */
export function instanceOfCreateLogin(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "email" in value;

  return isInstance;
}

export function CreateLoginFromJSON(json: any): CreateLogin {
  return CreateLoginFromJSONTyped(json, false);
}

export function CreateLoginFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateLogin {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: json["email"],
    remember_me: !exists(json, "remember_me") ? undefined : json["remember_me"],
    next: !exists(json, "next") ? undefined : json["next"],
  };
}

export function CreateLoginToJSON(value?: CreateLogin | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    remember_me: value.remember_me,
    next: value.next,
  };
}
