/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Customer } from "./Customer";
import {
  CustomerFromJSON,
  CustomerFromJSONTyped,
  CustomerToJSON,
} from "./Customer";
import type { Picture } from "./Picture";
import {
  PictureFromJSON,
  PictureFromJSONTyped,
  PictureToJSON,
} from "./Picture";

/**
 *
 * @export
 * @interface Puzzle
 */
export interface Puzzle {
  /**
   *
   * @type {Customer}
   * @memberof Puzzle
   */
  customer?: Customer;
  /**
   *
   * @type {string}
   * @memberof Puzzle
   */
  subject: string;
  /**
   *
   * @type {string}
   * @memberof Puzzle
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof Puzzle
   */
  live_until?: string | null;
  /**
   *
   * @type {Picture}
   * @memberof Puzzle
   */
  picture?: Picture;
  /**
   *
   * @type {string}
   * @memberof Puzzle
   */
  prize_short_description?: string;
  /**
   *
   * @type {string}
   * @memberof Puzzle
   */
  prize_description?: string;
  /**
   *
   * @type {Picture}
   * @memberof Puzzle
   */
  prize_picture?: Picture;
  /**
   *
   * @type {number}
   * @memberof Puzzle
   */
  user_solved?: number | null;
  /**
   *
   * @type {number}
   * @memberof Puzzle
   */
  participants_count?: number | null;
  /**
   *
   * @type {number}
   * @memberof Puzzle
   */
  participants_correct?: number | null;
  /**
   *
   * @type {string}
   * @memberof Puzzle
   */
  winner_description?: string;
  /**
   *
   * @type {string}
   * @memberof Puzzle
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Puzzle
   */
  qrcode_base64_image: string;
  /**
   *
   * @type {string}
   * @memberof Puzzle
   */
  created_at: string;
}

/**
 * Check if a given object implements the Puzzle interface.
 */
export function instanceOfPuzzle(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "subject" in value;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "qrcode_base64_image" in value;
  isInstance = isInstance && "created_at" in value;

  return isInstance;
}

export function PuzzleFromJSON(json: any): Puzzle {
  return PuzzleFromJSONTyped(json, false);
}

export function PuzzleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Puzzle {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    customer: !exists(json, "customer")
      ? undefined
      : CustomerFromJSON(json["customer"]),
    subject: json["subject"],
    description: !exists(json, "description") ? undefined : json["description"],
    live_until: !exists(json, "live_until") ? undefined : json["live_until"],
    picture: !exists(json, "picture")
      ? undefined
      : PictureFromJSON(json["picture"]),
    prize_short_description: !exists(json, "prize_short_description")
      ? undefined
      : json["prize_short_description"],
    prize_description: !exists(json, "prize_description")
      ? undefined
      : json["prize_description"],
    prize_picture: !exists(json, "prize_picture")
      ? undefined
      : PictureFromJSON(json["prize_picture"]),
    user_solved: !exists(json, "user_solved") ? undefined : json["user_solved"],
    participants_count: !exists(json, "participants_count")
      ? undefined
      : json["participants_count"],
    participants_correct: !exists(json, "participants_correct")
      ? undefined
      : json["participants_correct"],
    winner_description: !exists(json, "winner_description")
      ? undefined
      : json["winner_description"],
    id: json["id"],
    qrcode_base64_image: json["qrcode_base64_image"],
    created_at: json["created_at"],
  };
}

export function PuzzleToJSON(value?: Puzzle | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    customer: CustomerToJSON(value.customer),
    subject: value.subject,
    description: value.description,
    live_until: value.live_until,
    picture: PictureToJSON(value.picture),
    prize_short_description: value.prize_short_description,
    prize_description: value.prize_description,
    prize_picture: PictureToJSON(value.prize_picture),
    user_solved: value.user_solved,
    participants_count: value.participants_count,
    participants_correct: value.participants_correct,
    winner_description: value.winner_description,
    id: value.id,
    qrcode_base64_image: value.qrcode_base64_image,
    created_at: value.created_at,
  };
}
